.navbar.custom-navbar {
  background: transparent;
}

/* @media (max-width: 767px) {
  .navbar.custom-navbar {
    position: fixed;
    bottom: 0;
    left: 0;
    right: 0;
    border-radius: 30px 30px 0 0;
    overflow: hidden;
    backdrop-filter: blur(10px);
    background-color: rgba(255, 255, 255, 0.205);
    border: 1px solid var(--primary-color);
    color: var(--primary-color);
    border-radius: 5px;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 5px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
    z-index: 2;
    margin-bottom: 10px; /* Adjust this value to create the desired space 
    padding: 30px;
  }
}
*/



.navbar-brand {
  color: var(--white);
  font-size: 2rem;
  font-weight: bold;
}
  
  .custom-button-outline {
    border-color: var(--white);
    color: var(--white);
  }
  
  .custom-button-outline:hover {
    background: var(--white);
    color: var(--primary-color);
  }
  
  .navbar-menu-container {
    background: rgba( 255, 255, 255, 0.1 );
    box-shadow: 0 8px 32px 0 rgba( 31, 38, 135, 0.37 );
    backdrop-filter: blur( 4px );
    -webkit-backdrop-filter: blur( 40px );
    border-radius: 10px;
    border: 1px solid rgba( 255, 255, 255, 0.18 );
    border-radius: 5px;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 5px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  }
  
  .nav-link {
    color: var(--white);

  }
  
  .nav-link.active {
    color: var(--primary-color);
    background: var(--white);
    border-bottom: 3px solid var(--primary-color);
    
  }
  
  .nav-link:hover {
    color: var(--primary-color);
    background: var(--white);
    border-bottom: 3px solid var(--primary-color);
  }
  
  @media (max-width: 767.98px) {
    .navbar-menu-container {
      display: none;
    }
  }
  
  .custom-offcanvas {
    background-color: rgba(87, 87, 87, 0.274) !important;
    backdrop-filter: blur(10px) !important;
    color: var(--primary-color) !important;
    padding: 5px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  }
 /* For non-active links */
.custom-offcanvas .nav-link {
    color: #fff !important;
  }
  
  /* For active links */
  .custom-offcanvas .nav-link.active {
    color: var(--primary) !important;
  }

  .custom-offcanvas .btn-close svg {
    fill: #fff !important;
  }


  