.chat-form {
  max-width: 600px;
  border-radius: 50px;
  box-shadow:
  0px 1.6px 2.2px rgba(0, 0, 0, 0.052),
  0px 3.9px 5.3px rgba(0, 0, 0, 0.081),
  0px 7.3px 10px rgba(0, 0, 0, 0.102),
  0px 13px 17.9px rgba(0, 0, 0, 0.12),
  0px 24.2px 33.4px rgba(0, 0, 0, 0.143),
  0px 58px 80px rgba(0, 0, 0, 0.2)
;
  backdrop-filter: blur(50.0px);
  -webkit-backdrop-filter: blur(50.0px);
  border: 1px solid rgba(151, 151, 151, 0.274);
  display: flex;
  flex-direction: column;
  position: relative;
  background: radial-gradient(circle at 20% 0%, rgba(239, 101, 77, 0.247) 0%, rgba(0,0,0,0) 50%),
  radial-gradient(circle at 80% 100%, rgba(77, 128, 239, 0.247) 0%, rgba(0,0,0,0) 50%);
}

.chat-message {
  background: rgba(255, 255, 255, 0.2);
  border-radius: 15px;
  padding: 15px;
  max-width: 80%;
  line-height: 1.5;
  position: relative;
  margin-bottom: 30px;
}

.chat-message p {
  margin: 0;
}

.chat-message input {
  background: none;
  border: none;
  border-bottom: 1px solid #ffffff;
  outline: none;

  color: #ffffff; 
}

.chat-message input::placeholder,
.chat-message textarea::placeholder {
  color: rgba(255, 255, 255, 0.5);
}

.chat-message.right {
  background-color: #18181870;
  color: #ffffff;
  text-align: right;
  margin-left: auto;
}

.input-area {
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-radius: 20px;
  background-color: rgba(255, 255, 255, 0.2);
  padding: 5px 10px;
  margin-top: 10px;
}

.input-area input {
  flex-grow: 1;
  background-color: transparent;
  border: none;
  color: white;
  outline: none;
  padding: 5px 0;
}

.input-area button[type="submit"] {
  background-color: #0b93f6;
  color: white;
  border: none;
  padding: 10px 20px;
  border-radius: 20px;
  cursor: pointer;
}

.input-message {
  display: flex;
  justify-content: space-between;
  align-items: center;
  background: rgba(255, 255, 255, 0.349);
  border-radius: 20px;
  padding: 10px;
  margin-top: 20px;
}

.input-message input {
  flex-grow: 1;
  background: none;
  border: none;
  color: #ffffff;
  padding: 0;
  margin-right: 10px;
  outline: none;
}
.input-message input::placeholder {
  color: #ffffff; /* Replace with your desired color */
}

.input-message button[type="submit"] {
  background-color: #0076ca;
  color: white;
  border: none;
  padding: 10px 20px;
  border-radius: 20px;
  cursor: pointer;
}

.receiver-icon {
  height: 40px;
  width: 40px;
  border-radius: 50%;
  margin-right: 10px;
  background: linear-gradient(to right, #3498db, #2ecc71);
  margin-bottom: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.sender-icon {
  height: 40px;
  width: 40px;
  border-radius: 50%;
  margin-left: 10px;
  background: linear-gradient(to right, #9b59b6, #f1c40f);
  display: flex;
  justify-content: center;
  align-items: center;
}


.message-row {
  display: flex;
  align-items: flex-start;
  margin-bottom: 20px;
}

.message-row.right {
  justify-content: flex-end;
}
